/* eslint-disable react/jsx-indent */
import React, { useContext } from 'react'

import { ScrollContainer } from '../../../context/scrollBehavior'
import AnimateOnEntrance from '../../fx/animate/AnimateOnEntrance'
import RegisterSignUp from '../../global/RegisterSignUp'
import { UserContext } from '../../../context/user/context';
import ImageInView from '../../fx/animate/ImageInView';

export default function EmailForm({device}) {
    const { state } = useContext(UserContext);
    const { user } = state;

    /* console.log(user.isLoggedIn); */
    if ( user.isLoggedIn ) return null;

    return (
        <ScrollContainer force={true}>
        <section className="grid-section grid-content-container t-left email-section set-up-account">
            <div className="t-content-container t-left">
                <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                    <div className="grid-col form-set-up-account">
                        <div className="t-heading-container">
                            <AnimateOnEntrance className="fadeInLeft" device={device}>
                                <h3 className="t-mxlarge t-heading">Become a member!</h3>
                            </AnimateOnEntrance>
                            <div className="t-content-container t-left t-callout">
                                <AnimateOnEntrance className="fadeInLeft" device={device}>
                                    <p>
                                    As a member of My Loch Gallery you can set up a collector profile, save artworks and be notified of new work by your favourite atists, access to our newsletter, and more.
                                    </p>
                                </AnimateOnEntrance>
                            </div>
                        </div>
                        <AnimateOnEntrance className="fadeInLeft" device={device}>
                            <RegisterSignUp />
                        </AnimateOnEntrance>
                    </div>
                    <div className="grid-col mobile-no">
                        <AnimateOnEntrance className="fadeInRight" device={device}>
                            <ImageInView src={"/media/su_grid.jpg"} />
                        </AnimateOnEntrance>
                    </div>
                </div>
            </div>
        </section>
    </ScrollContainer>
    )
}