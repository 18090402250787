/**
 * Return with Fallback
 * Take an optional parameter
 * check if it exists
 * return the existing property
 * or return the fallback on null / falsy value
 * @param fallback the fallback
 * @param optional the optional field to use
 * @param {Bool} list boolean value. is the returned set a list?
 * @param {String} list_key if the list is a list of objects, what key are we returning the value from?
 * @returns {*}
 */
export default function returnWithFallback({
  fallback,
  optional,
  list = false,
  list_key = false,
}) {
  if (fallback && fallback[optional]) {
    for (let i = 0; i < 9; i++) {
      if (list && fallback[optional][i]) {
        if (list_key && fallback[optional][i][list_key]) {
          return fallback[optional][i][list_key]
            ? fallback[optional][i][list_key]
            : fallback;
        }
        // return fallback[optional][i] ? fallback[optional][i] : fallback;
      }
    }

    if (list_key) {
      return fallback[optional][list_key]
        ? fallback[optional][list_key]
        : fallback;
    }
  }

  return fallback && fallback[optional] ? fallback[optional] : fallback;
}
